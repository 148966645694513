export class TestingAudio{

    private static buttonElement;

    public static Init(){

        TestingAudio.buttonElement = document.getElementById("myBtn");

        if(TestingAudio.buttonElement){
            TestingAudio.buttonElement.addEventListener("click", TestingAudio.onButtonClick);
        }


        

    }

    private static async onButtonClick(){

        let container = document.getElementById("container");

        let audioContext = new AudioContext();
        var merger = audioContext.createChannelMerger(1);
       
        merger.connect(audioContext.destination);        


        for(let i=1; i < 20; ++i){
            let src = `./assets/videos/FS${i}.mp4`;

            console.log(src);

            let htmlVideoElement = document.createElement("video") as HTMLVideoElement;
            htmlVideoElement.src = src;
            htmlVideoElement.loop = true;
            await htmlVideoElement.play();

            container.appendChild(htmlVideoElement);

            let videoSource = audioContext.createMediaElementSource(htmlVideoElement);
            //var compressor = audioContext.createDynamicsCompressor();
            var gainNode = audioContext.createGain();
            gainNode.gain.setValueAtTime(0.1, audioContext.currentTime);

            videoSource.connect(gainNode).connect(merger);
            

            await new Promise(r => setTimeout(r, Math.random() * 1000));

        }

       
        

    }
    

}